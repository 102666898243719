export const volumes = ['', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'].reverse();

export const ocrReadableFileTypes = [
    1,2,3,4,5,6,7
]

export const css = {
    darkGrey: '#262626',
    darkOrange: '#bd9f50',
    lightGrey: '#d4d4d4'
}

export const datalistTables = [
    'contact',
    'project',
    'task_type'
]

export const gapiScopes = '' +
    'email ' +
    'profile ' +
    'openid ' +
    'https://www.googleapis.com/auth/script.scriptapp ' +
    'https://www.googleapis.com/auth/spreadsheets ' +
    'https://www.googleapis.com/auth/documents ' +
    'https://www.googleapis.com/auth/script.external_request ' +
    'https://www.googleapis.com/auth/drive ' +
    'https://www.googleapis.com/auth/gmail.send ' +
    'https://www.googleapis.com/auth/gmail.readonly '+
    'https://www.googleapis.com/auth/gmail.compose ' +
    'https://www.googleapis.com/auth/gmail.modify ' +
    'https://mail.google.com';
